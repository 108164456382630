
<template>
  <el-select
      v-model="platform_id"
      placeholder="请选择平台"
      size="small"
      filterable
      clearable
      @change="platChange"
  >
    <el-option
        v-for="item in optionsProvince"
        :key="item.platform"
        :label="item.platform_unique"
        :value="item.platform"
    ></el-option>
  </el-select>
</template>
<script>
import api from '@/api/tongji'
export default {
  name:'platSelect',
  props:{
    plat_id:{
      type: String,
      default:''
    },
    is_asset:{
      type: String,
      default:''
    }
  },
  data(){
    return{
      platform_id: '',
      optionsProvince: [], //平台下拉框
    }
  },
  mounted() {
    if(this.plat_id){
      this.platform_id = this.plat_id
    }
    this.platformFun()
  },
  methods:{
    //平台下拉框
    platformFun() {
      api.platform({is_asset:this.is_asset}).then((res) => {
        this.optionsProvince = res.data
      })
    },
    platChange(val){
      this.$emit('platSelected', val)
    }
  }
}
</script>
<style scoped lang="scss">

</style>